import React, { Component } from 'react';
import { Panel } from 'primereact/panel';
import '../hatul.css';
import { Tag } from 'primereact/tag';
import Contacts from './Contacts.js';
export default class MainPage extends Component {
    state = {
         value1:'',value2:'',value3:'',
    }
       
    render() {
         return(<Panel>     
            <div className="p-d-flex p-flex-column p-flex-md-row">
               <div p-d-flex p-flex-row p-flex-md-column>
                     <img src="assets/images/globalCat.jpg" alt="Кот на глобусе" className='p-page-img' />
                  <Contacts/>
                 
               </div>
               {/* <div className="p-mr-5 p-pt-6 "> */}
               <div className="p-mr-5 p-pt-6 p-md-6">
                  <h2 className="p-orange p-text-center" style={{margin: '0.5em 0 0.5rem 0', lineHeight: '1.5'}} >Вас приветствует школа "Хатуль мадан".</h2>
                  <h4 className="p-orange p-text-center" style={{margin: '0.5rem 0 0.5rem 0',lineHeight: '1.5'}}>Наша миссия — ввести взрослых и детей в мир высоких технологий.</h4> 
                  <h4 className="p-orange p-text-center" style={{margin: '0.5rem 0 0.5rem 0',lineHeight: '1.5'}}> Рассказываем просто о сложном. </h4>
                  <div >
                     <h5 className="p-text-justify"> 
                      <p><b>Мы предлагаем:</b></p>
                      <b>Взрослым</b>: ручное и автоматизированное тестирование, тренинги по подготовке к собеседованиям,
                     изучение языков Python, JavaScript, технический английский и многое другое. <a className="p-addlink" href="/courses#c00"> Подробнее о курсах<i className="pi pi-arrow-right" ></i> </a> 
                     </h5><h5>Курсы для взрослых рассчитаны на занятых людей, которые хотят сменить сферу деятельности или повысить свою квалификацию. 
                     Поэтому занятия проводятся по вечерам,  в удобное для всех время. 
                     В ходе обучения предлагаем всем желающим поработать с реальными проектами, получить практический опыт и решать 
                      задачи с собеседований. Основной формат обучения - <b>IT bootcamp,</b> подробнее <a className="p-addlink" href=" /projects"> тут<i className="pi pi-arrow-right" > </i> </a></h5>
                     <h5 className="p-text-justify"><b>Детям:</b> программирование на Python, 
                     а для самых маленьких  — на Scratch игр разной степени сложности; хитрые задачи "Занимательной математики"; путешествия в пространстве и времени с "Необыкновенным английским".  
                     Научим детей проводить время за компьютером с пользой.
                     <a  className='p-addlink' href="/courses#c01"> Подробнее о курсах<i className="pi pi-arrow-right" > </i> </a> </h5>
                     <h5>Занятия проходят <b>онлайн</b>. Просто подключайтесь!    <a className="p-addlink" href="/faq">Подробнее о занятиях<i className="pi pi-arrow-right" > </i></a>. </h5>
                  </div>
            
                  <div className="p-d-flex p-flex-column p-flex-md-row">
                     <div className='p-pt-2 p-pr-2' >
                        <h5 className=" p-orange">  Объявления </h5>            
                        <p>  Публикуем вакансии в IT из дружественной  </p>
                       <p>HR-компании. Смотрите в  <i className="pi pi-facebook" ></i> и  <i className="pi pi-telegram" > </i></p>
                        <p> 
                           Приглашаем на курс  <a className="p-mylink" href="/courses#c1"> QA для начинающих</a></p>
                           <p>  Приглашаем на курс <a className="p-mylink" href="/courses#c8">Технический английский</a></p>
                           <p>  Приглашаем на курс  <a className="p-mylink" href="/courses#c8">Технический иврит</a></p>            
                     </div>
                     <div className='p-pt-2 p-pr-5'>
                     <h5 className=" p-orange ">  FAQ </h5> 
                     <p><a className="p-mylink" href="/faq#f2">Зачем</a> и <a className="p-mylink" href="/faq#f3">Как</a> учить детей программированию?</p>
                     <p><a className="p-mylink" href="/faq#f4">Какой</a> уровень знаний нужен для курса QA?</p>
                     <p><a className="p-mylink" href="/faq#f6">Перспективы</a> трудоустройства после курса QA?</p>
                     <p><a className="p-mylink" href="/faq#f7">Войти в IT</a> легенды и мифы</p>
                  </div>
               </div>                            
            </div>  
         </div>
          </Panel>
       )     
    }
}