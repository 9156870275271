import React from 'react';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import '../hatul.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import { InputText } from "primereact/inputtext"
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
//import { fetchActiveUsers } from "../service/CommonDataSrv";
import { Column } from "primereact/column";
import { apiUrl, axinst,processError} from "../axInst";
import {UserProxy} from  "./UserProxy"

export const Users = (props) =>{
    const [listUsers, setListUsers] = useState()  //все, тут хранятся значения если применяется фильтр
    const [listUsersFl, setListUsersFl] = useState() //список который показывается
    const [userId, setUserId] = useState()
    const [selectedUser,setSelectedUser] = useState()
    const toasts = useRef()
    const [fldFullname, setFldFullname] = useState('')
    const [fldPhone, setFldPhone] = useState('')
    const [fldNote, setFldNote] = useState('')
    const userChanged = useRef(false)
    const [nameFl, setNameFl] = useState(null)
    const [phoneFl, setPhoneFl] = useState(null);
    const [listFull, setListFull] = useState(null)
    useEffect(()=>{
        fetchUsers(toasts)  
    },[])
   const [userAuth,setUserAuth]=useState("")
   const [userGroup,setUserGroup]=useState("")
   //const [filterName, setFilterName] = useState('');
   const fetchUsers = (toasts) => {
        
    axinst.get('/users/getGroupMembers', {
        timeout: 20000,
     })
    .then((response) => {
        let result = response.data
        result.sort((a, b) => {
            const nameA = a.userName.toUpperCase(); // ignore upper and lowercase
            const nameB = b.userName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          
            // names must be equal
            return 0;
          });
        setListUsers(result)
        setSelectedUser(null)
        //filter(nameFl) 
        setListUsersFl(result)
    })
    .catch((err)=>
        console.log(err)
    )
    
}
const fetchUsersFull = (toasts) => {
        
    axinst.get('/users/getAll', {
        timeout: 20000,
     })
    .then((response) => {
        let result = response.data
        setListFull(result)
    })
    .catch((err)=>
        console.log(err)
    )
    
}
    const fetchUserById=(id) => {
        if (!id) 
           return
        axinst.get("/users/getById/"+id)
        .then((response) => {
            //setSelectedUser(response.data)
            if(response.data.fullname) 
            {
                setFldFullname(response.data.fullname)
            }
            else {
                setFldFullname("")
            }
            if (response.data.phone) {
                setFldPhone(response.data.phone)
            } else {
                setFldPhone("")
            }
            if(response.data.note){
                setFldNote(response.data.note)
            } else {
                setFldNote("")
            }
            setAuth(response.data) 
            setGroups(response.data)
        })
        .catch((err)=>
            console.log(err)
        )
    }
    
    
    const setAuth=(value)=>{
       // {"authorities":[{"id":1,"name":"super","authority":"super"}]}
     let all=value.authorities  
     let s=""
     for (let i = 0; i < all.length; i++) {
         s=s+all[i].authority+" "
     }
     setUserAuth(s) 
    }
     const setGroups=(value)=>{
         //{"groups":[{"id":1,"version":1,"sortOrder":10,"name":"QA-9","schedule":"вторник 20:30","course":1,"courseName":"0","deleted":false},{"id":2,"version":1,"sortOrder":9,"name":"QA-8","schedule":"среда 20:15","course":1,"courseName":"0","deleted":false}]} 
        let all=value.groups
        let s=""
        for (let i = 0; i < all.length; i++) {
            s=s+all[i].name+" "
        }
        setUserGroup(s)
    }
    const onUserSelect = (value) => {
        setUserId(value.id)
        setSelectedUser(value)
        fetchUserById(value.id)
     }
     const saveUser=()=>{
        let proxy=new UserProxy(userId, fldFullname, fldPhone, fldNote, userAuth )
         axinst.post("/users/save", proxy)
         .then((response) => {
            toasts.current.show({severity:"success", summary:'Готово', detail: "Успешно сохранено"})
             userChanged.current = false
             
             
         })
         .catch(err=>{
             const errMsg = processError(err)
             toasts.current.show({severity:"error", summary:"Ошибка", detail: errMsg})
         })
     }
     const cardHeader = () => {
        return <div className="p-d-flex p-d-column" style={{padding:'5px'}}>
            <Button className="p-button-rounded p-ml-5" icon="pi pi-arrow-left" tooltip="Вернуться на предыдущую страницу"
                onClick={()=>props.history.goBack()}></Button>
            <div className="p-card-title p-ml-5 p-my-2">Список активных пользователей</div>
        </div>
    }
    const onDeleteUser = (value) => {
        //заменить на свою передаем   user 
      /*   axinst.post('/users/delete/' + value.id + "/" + selectedGroup.id)
        .then(response=>
            setGroupUsers(response.data))
        .catch(err => {
            const errMsg = processError(err)
            toasts.current.show({severity:"error", summary:"Ошибка", detail: errMsg})
        }) */
    }

    const filter=(mode)=>{
      if(listUsers){
        if (mode){
            let buf=[]
            listUsers.forEach(element => {
               if (element.userName.includes(nameFl) & element.phone.includes(phoneFl)) {
                   buf.push(element)
               }  
            });  
            setListUsersFl(buf)
        }  else {
            setListUsersFl(listUsers)//буфер для хранения списка
            setNameFl("")
            setPhoneFl("")
        }
      }
    }  
    const header = (
        <div >
           Найти:&nbsp;        
            <InputText id="nameF" className="p-inputtext-sm block mb-2" placeholder="логин" 
               value={nameFl} style={{ width: '100px' }}  onChange = {(e)=>{setNameFl(e.target.value)}}/>
            <InputText id="phoneF" className="p-inputtext-sm block mb-2" placeholder="телефон" 
               value={phoneFl}  style={{ width: '100px' }} onChange = {(e)=>{setPhoneFl(e.target.value)}}/>
            <Button  icon="pi pi-search" onClick={(e)=>filter(true)}></Button>
            <Button icon="pi pi-times" onClick={(e)=>filter(false)}></Button>
      
       </div>
    );
    const changeAuth=()=>{
        userChanged.current=true
        if (selectedUser){
            if (userAuth==""){
                setUserAuth("super") 
            }else{
                setUserAuth("") 
            }
        }
        //authorities - super
    }
    const toPay=()=>{
        props.history.push({pathname: '/pay', state: {user: selectedUser}})
    }
    const displayUser=()=>{
        return(<Panel>
           
           {/* <div className="p-grid"> */}
           <div className="p-orange p-pt-4"> <h5>Пользователь {selectedUser&&selectedUser.userName}</h5></div>
                <div className="p-float-label p-my-3">
                        <InputText id="Fullname" value={fldFullname}  style={{ width: '30%' }}
                                    onChange = {(e)=>{setFldFullname(e.target.value); userChanged.current = true}}/>
                        <label htmlFor="Fullname">Имя пользователя</label> 
                     
                </div>
                <div className="p-float-label p-my-3">
                        <InputText id="fldPhone" value={fldPhone}  style={{ width: '30%' }}
                                    onChange = {(e)=>{setFldPhone(e.target.value); userChanged.current = true}}/>
                        <label htmlFor="fldPhone">телефон</label> 
                     
                </div>
                <div className="p-float-label p-my-3">
                        <InputText id="fldNote" value={fldNote}  style={{ width: '60%' }}
                                    onChange = {(e)=>{setFldNote(e.target.value); userChanged.current = true}}/>
                        <label htmlFor="fldNote">Примечания</label> 
                     
                </div>
                <div className="p-orange" > доступ: <b>{userAuth} </b>  
                {selectedUser&&(!userAuth)&& <Button className="p-button-rounded p-button-outlined p-mr-3" label="дать" onClick={changeAuth}></Button> }
                {selectedUser&&userAuth&&<Button className="p-button-rounded p-button-outlined p-mr-3" label="отменить" onClick={changeAuth}></Button>}
                </div>
                <div className="p-orange">
                <p>группы: <b>{userGroup}</b> </p>
                </div>
                {selectedUser && <Button label="Оплаты" className="p-button-rounded p-button-outlined p-mr-3" onClick={toPay}></Button>}
                <div className="p-d-flex p-jc-around">
                    <Button label="Выйти" onClick={props.history.goBack}></Button>
                    {userChanged.current &&  
                        <Button label="Сохранить" onClick={saveUser}></Button>}
                        
                </div>
                
                
        </Panel>
         )   
    }
    return(<Panel>
    {cardHeader()}
       <div className="p-grid">
        <Toast ref={toasts} position = {"top-left"} life='5000'></Toast>
        <div className="p-col-5">
            <DataTable value={listUsersFl} header={header} selection={selectedUser} selectionMode="single" 
                scrollable  scrollHeight="400px"
                onSelectionChange={e=>onUserSelect(e.value)}
                dataKey="id" responsiveLayout="scroll"
                emptyMessage="Нет данных">
                 <Column field="userName" header="Логин" />
                <Column field="fullname" header="Имя"/>
               <Column field="phone" header="Телефон"/> 
               <Column field="note" header="Примечание"/> 
                {/* <Column icon="pi pi-times" body={userListDeleteColumn} style={{padding:'3px', margin:'0px'}}/> */}
            </DataTable>
        </div>
        <div className="p-col-7">
        {displayUser()}
        </div>
        <div className="p-col-10"><Button className="p-button-rounded p-ml-5" icon="pi pi-arrow-down" tooltip="Загрузить"
                onClick={fetchUsersFull}></Button> 
        <div className="p-card-title p-ml-5 p-my-2">Список всех пользователей</div>
        </div>
        <div className="p-col-5">
            <DataTable value={listFull}  selection={selectedUser} selectionMode="single" 
                scrollable  scrollHeight="400px"
                onSelectionChange={e=>onUserSelect(e.value)}
                dataKey="id" responsiveLayout="scroll"
                emptyMessage="Нет данных">
                 <Column field="userName" header="Логин" />
                <Column field="fullname" header="Имя"/>
               <Column field="phone" header="Телефон"/> 
               <Column field="note" header="Примечание"/> 
            </DataTable>
        </div>
    </div>
    </Panel>)
}