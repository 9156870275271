import React, { useState, useRef, useEffect } from "react";
import { DataView } from 'primereact/dataview';
import { Toast } from 'primereact/toast'
import { ProgressBar } from 'primereact/progressbar';
import {Panel} from "primereact/panel"
import {Card} from "primereact/card"
import { apiUrl, axinst} from "../axInst";
import { Button } from 'primereact/button'
import Contacts from './Contacts.js';
import '../hatul.css';



export const NewsDPage = (props) =>{
    const toasts = useRef()
    const [articles, setArticles] = useState(null)
    const [artCount, setArtCount] = useState(0)
    const [layout, setLayout] = useState('list');
    const [pleaseWait, setPPleaseWait] = useState(true)
    const [pos, setPos]=useState(0)
    const lines=5
    useEffect(()=>{
        fetchArticles()
    },[pos])

    useEffect(()=>{
        fetchArticlesCount()
    },[])

    const fetchArticlesCount  = () => {
             axinst.get("getArticles/count/n", {
             timeout: 2000,
          })
        .then((response) => {
            const result = response.data
            setArtCount(result)
        })
        .catch((err)=>
            console.log(err)
        )    
    }
    const fetchArticles = () => {
        const full=true
        const tag="n"
        axinst.get("getArticles/"+full+"/"+tag+"/"+pos+"/"+lines, {
             timeout: 20000,
          })
        .then((response) => {
            const result = response.data
            let old=[]
            if (pos>0) old= articles
            old = old.concat(result);
            setArticles(old)
        })
        .catch((err)=>
            console.log(err)
        )
        .finally(()=>{setPPleaseWait(false)});     
    }

      const showImage=(file)=>{
       if (!file)    
            return(<img src='assets/images/greating.png'className="p-news-img" alt="img" />)
        const srcValue="data:image/png;base64,"+file
        return(
          <img src={srcValue} className="p-news-img" alt="img" />
          )
    }
      
    const renderListItem = (article) => {
        return (
                <Card id={article.type+article.id} title={article.titleA}>  
                    <div className="p-m-0" style={{lineHeight: '1.5'}}>
                        <div className="p-d-flex p-flex-column p-flex-sm-row">
                            <div className="p-ml-2">
                               {showImage(article.image)} 
                            </div> 
                            <div className="p-mx-2 p-text-justify" style={{width:'60%'}}>
                                        <div dangerouslySetInnerHTML={{__html:  article.textA}}></div>
                                         {article.link&&<a className="p-mylink" href={article.link}  target="_blank" rel='noreferrer'> ссылка </a> }
                            </div>

                         </div>   
                    </div>
                </Card> 
        );
    }
    const showItemTemplate = (article, layout) => {
        if (!article) {
            return;
        }
        return renderListItem(article)
     }
    
    const header = () =>{
    return (<h5 className="p-orange">Последние новости</h5>)
    }
    const myHeader=header()

    const load=()=>{
        if (artCount>pos+lines) setPos(pos+lines)
        //fetchArticles()
    }
    return(
        <Panel>
        <h3  className=" p-pt-6 p-orange p-text-center">Новости, объявления, истории, отчеты о мероприятиях    </h3>
        <div className="p-d-flex p-flex-column p-flex-md-row ">
            <div className="p-pl-4">
                <p> <img src="assets/images/mechHatul.jpeg"  className="p-page-img" alt="картинка с котом"/> </p>
                <p className="p-orange"> А также следите за новостями в наших группах в facebook:
                <ui>
                    <li><a className="p-mylink" href="https://www.facebook.com/groups/Khatulmadan"  target="_blank" rel='noreferrer'> Khatulmadan </a> </li>
                    <li><a className="p-mylink" href="https://www.facebook.com/groups/RusITIsrael"  target="_blank" rel='noreferrer'> RusITIsrael </a></li>
                </ui>
                </p>
                <Contacts/>
            </div>
            
            <div className="p-px-3 ">
               
                <div className="dataview-demo">        
                {pleaseWait && <ProgressBar mode="indeterminate" ></ProgressBar>}                                      
                        <DataView value={articles} layout={layout} 
                                itemTemplate={showItemTemplate} 
                                emptyMessage="Загрузка"/>   
                </div>   
                {(artCount>pos+lines)&&<Button label="больше материалов" className="p-button-outlined" onClick={load}></Button> }
              
            </div>
        </div>
        <small>загружено {pos+lines} </small>   
        </Panel>
    )
}


