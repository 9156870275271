import React from 'react';
import { useState } from 'react';
import { useEffect, useRef } from 'react';
import '../hatul.css';
import { ProgressSpinner } from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import { InputText } from "primereact/inputtext"
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { apiUrl, axinst,processError} from "../axInst";
import { useLocation } from 'react-router-dom'
import {Calendar} from "primereact/calendar";
import { InputNumber } from 'primereact/inputnumber'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea';
import User from "../wrapers/User";
import { AutoComplete } from 'primereact/autocomplete'

//юзер попадая на страницу видит свои платежи
//супервизор видит выбранного юзера если зашел через страницу users или  groups иначе сразу требуется выбрать юзера

export const Payments = (props) =>{
    const [listPayments, setListPayments] = useState();
    const toasts = useRef();
    const location = useLocation();
    const state = props.location.state;
    const [fldComment, setFldComment] = useState()
    const [fldGroup, setFldGroup] = useState()
    const [fldDate, setFldDate] = useState()
    const [fldSum, setFldSum] = useState()
    const PaymentChanged = useRef(false)
    const [groups, setGroups] = useState()
    const moment = require('moment');
    const [user] = useState(User.load())
    const [student, setStudent]=useState(state?location.state.user:User.load()) ;
    const [listUsers,setListUsers]=useState()
    const [selPayment,setSelPayment] = useState()
    
    useEffect(()=>{
        fetchPayments(toasts)  
        fetchGroups(toasts)
        fetchUsers(toasts)
    },[])
    
    const fetchPayments = (toasts) => {
        
        axinst.get('/users/getInfo/'+student.id, {
            timeout: 20000,
         })
        .then((response) => {
            let result = response.data
            setListPayments(result)
        })
        .catch((err)=>
            console.log(err)
        )
    }
    const fetchGroups = (toast) => {
        axinst.get('dictionary/group/getAll/false')
        .then((response) => 
            setGroups(response.data)
        )
        .catch((err) => {
            if (toast){
                const errMsg = processError(err)
                toast.current.show({severity:"error", summary:"Ошибка", detail: errMsg})
            }
        })
    }  
    const onGroupChoose = (value) => {
            setFldGroup(value)
    }   
    class PaymentProxy{
        constructor (userid, psum,pdate,comment, groupId ){
            this.id=null
            this.userId = userid
            this.psum = psum
            this.pdate = pdate
            this.comment = comment
            this.groupName = groupId
        }
    }
    const clearFields = () => {
        setFldComment("")
        setFldDate("")
        setFldSum("")
        setFldGroup(null)

    }
    const savePayment = () => {
        let paymentDate = moment(moment(fldDate).format("DD/MM/YYYY")).utcOffset((new Date()).getTimezoneOffset(), false)
        let proxy=new PaymentProxy(student.id, fldSum, paymentDate.toISOString(), fldComment,fldGroup.id )
        if (selPayment){
            proxy.id=selPayment.id}
        axinst.post("/users/paymentsave", proxy)
        .then((response) => {
           toasts.current.show({severity:"success", summary:'Готово', detail: "Успешно сохранено"})
            PaymentChanged.current = false
            clearFields()
            
        })
        .catch(err=>{
            const errMsg = processError(err)
            toasts.current.show({severity:"error", summary:"Ошибка", detail: errMsg})
        })  
    }
    const fetchUsers = (toasts) => {
        
        axinst.get('/users/getAll', {
            timeout: 20000,
         })
        .then((response) => {
            let result = response.data
            setListUsers(result)
        })
        .catch((err)=>
            console.log(err)
        )
        
    }  
         
         
    const fetchPayById = (id) =>{
      if (!id) 
        return
      axinst.get("/users/getPaymentById/"+id)
        .then((response) => {
        //устанавливаем значния полей 
        if(response.data.groupName) {  
            for (let i in groups){
             if (groups[i].name==response.data.groupName){
                setFldGroup(groups[i])
             }
            }
             //setFldGroup(response.data.groupName)
         }
         else {
             setFldGroup("")
         }
         if (response.data.psum) {
             setFldSum(response.data.psum)
         } else {
             setFldSum("")
         }
         if(response.data.comment){
             setFldComment(response.data.comment)
         } else {
             setFldComment("")
         }
         if(response.data.pdate){
            setFldDate(moment(response.data.pdate).toDate())
         } else {
            setFldDate("")
         }
       
     })
     .catch((err)=>
         console.log(err)
     )
    }
    const onPaySelect = (value) => {
        setSelPayment(value)
        fetchPayById(value.id)
     }       
    const cardHeader = () => {
        return <div className="p-d-flex p-d-column" style={{padding:'5px'}}>
            <Button className="p-button-rounded p-ml-5" icon="pi pi-arrow-left" tooltip="Вернуться на предыдущую страницу"
                onClick={()=>props.history.goBack()}></Button>
            <div className="p-card-title p-ml-5 p-my-2">Платежи за обучение</div>
        </div>
    }
    const displayPayment = () => {
        if (user.hasAuthorities("super")){
        return  <Panel>
        <div className="p-orange p-pt-4"> <h5>Студент {student.userName}</h5></div>
        <div className="p-float-label p-my-3">
        <Dropdown options={groups} optionLabel="name" id="fldGroup" maxLength={100}
            value = {fldGroup}
            onChange={(e)=>{onGroupChoose(e.target.value); PaymentChanged.current = true}}/>
        <label htmlFor="fldGroup"> Группа</label>
        </div>
        <div className="p-float-label p-my-3">
            <InputNumber id="fldSum"  value={fldSum} size={14}  
                        onChange = {(e)=>{setFldSum(e.value); PaymentChanged.current = true}}/>
            <label htmlFor="fldSum">Сумма платежа</label>
        </div>
        <div className="p-float-label">
        <Calendar id="fldDate" showSeconds={false} maxLength={100} required
            value={fldDate}  dateFormat="dd/mm/yy"
            onChange={(e)=>{setFldDate(e.target.value); PaymentChanged.current = true}} ></Calendar>
        <label htmlFor="fldDate">Дата платежа (DD/MM/YYYY)</label>
        </div>
        
        <div className="p-float-label p-my-3">
            <InputTextarea id="fldComment" value={fldComment} rows={5} cols={50}
                        onChange = {(e)=>{setFldComment(e.target.value); PaymentChanged.current = true}}/>
            <label htmlFor="fldComment">Примечание</label>
        </div>
        <div className="p-d-flex p-jc-around">
                    <Button label="Выйти" onClick={clearFields}></Button>
                    {PaymentChanged.current &&  
                        <Button label="Сохранить" onClick={savePayment}></Button>}
                        
        </div>
    
    </Panel>
    }}
    const filter=(mode)=>{
    }
    
    const header = ()=>{}
        
   
    const dateBodyTemplate = (rowData) => {
        //return dateTemplate(rowData.pdate);
        return moment(rowData.pdate).format("DD/MM/YYYY");
    };
    return(<Panel>
        {cardHeader()}
            <div className="p-orange p-pt-4"> Сделайте новый платеж или выберите платеж из списка для редактирования</div>
           <div className="p-grid">
            <Toast ref={toasts} position = {"top-left"} life='5000'></Toast>
            <div className="p-col-7">
                <DataTable value={listPayments} header={header} 
                    selection={selPayment} selectionMode="single" 
                    onSelectionChange={e=>onPaySelect(e.value)}
                    scrollable  scrollHeight="400px" dataKey="id" responsiveLayout="scroll"
                    emptyMessage="Нет данных">
                    <Column dataType="date" body={dateBodyTemplate} header="Дата"   />
                    <Column field="psum" header="Сумма"/>
                    <Column field="comment" header="Примечание"/> 
                    <Column field="groupName" header="Группа"/> 
                    {/* <Column icon="pi pi-times" body={userListDeleteColumn} style={{padding:'3px', margin:'0px'}}/> */}
                </DataTable>
            </div>
            <div className="p-col-5">
            {displayPayment()}
            </div>
        </div>
        </Panel>)
}